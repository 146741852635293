import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as constants from '../../constants/other';
import { useSelector } from 'react-redux';
import { MotifTabNavigation, MotifTabControl } from "@ey-xd/motif-react";

function DataRetentionManagementPolicy(props) {

    const params = useParams();
    const { clientId } = useParams();
    let dataPurgeConfigurationParameter_ARQC = null;
    let dataPurgeConfigurationParameter_Regular = null;
    let dataPurgeConfigurationParameter_ARQC_Value = null; // to set the default value while page loading
    let dataPurgeConfigurationParameter_Regular_Value = null; // to set the default value while page loading
    const convertWordToNum = num => ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"][num];

    const dataPurgeConfigurationValues = useSelector(
        state => state.dataPurgeConfigurationData
    );

    const isDecimal = (num) => {
        return num % 1 !== 0;
    }

    const [tabBarIndex, setTabBar] = useState(1);

    //Set current tab index
    const handleClick = (index) => {
        setTabBar(index);
    }

    dataPurgeConfigurationParameter_ARQC = dataPurgeConfigurationValues && dataPurgeConfigurationValues.find(item => item.dataPurgeConfigurationParameter === constants.DataPurgeConfigurationParameter_ARQC);

    dataPurgeConfigurationParameter_ARQC_Value = dataPurgeConfigurationParameter_ARQC ?
        isDecimal(dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue) ?
            dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue :
            convertWordToNum(dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue) : null;

    dataPurgeConfigurationParameter_Regular = dataPurgeConfigurationValues && dataPurgeConfigurationValues.find(item => item.dataPurgeConfigurationParameter === constants.DataPurgeConfigurationParameter_Regular) || null;
    dataPurgeConfigurationParameter_Regular_Value = dataPurgeConfigurationParameter_Regular ?
        isDecimal(dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue) ? dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue
            :
            convertWordToNum(dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue) : null;

    return (<div>
            <h2>Help Guide</h2>
            <div className="row mt-4">
                <div className="field col-md-2">
                    <MotifTabNavigation defaultActiveKey={0} id="helpGuideNav" orientation="vertical" lineSide="right" data-testid="helpGuide">
                        <MotifTabControl
                            className="d-flex align-items-center pt-2 pb-2 tabNavigation-hover"
                            style={{ background: "#FFF", marginBottom: "1px" }}
                            onClick={() => handleClick(1)}
                            key={0}>
                            <span className="helpGuideNavigation" data-testid="lblDataRetentionPolicyHelpGuide">Data Retention Policy</span>
                        </MotifTabControl>
                    </MotifTabNavigation>
                </div>
                <div className="field col-md-10">
                    {tabBarIndex === 1 &&
                        <div>
                            <h3 style={{ borderBottom: '1px solid #999999', lineHeight: '1.25em', paddingBottom: '23px' }}>REITSuite Data Retention Policy  </h3>
                            <br></br>
                            <p>
                                Welcome to the REITSuite Data Retention Policy. This policy outlines our rules and procedures for data management, including how long data is kept, when it is deleted, and who approves these deletions. Understanding these comprehensive guidelines is crucial to ensure the responsible and secure management of data within REITSuite.
                            </p>

                            <h4 id="overview" style={{ paddingBottom: '3px' }}>Data Stewards</h4>
                            <p>
                                Within REITSuite, the role of the Data Steward is pivotal to our Data Retention Policy. The engagement administrators act as Data Stewards for their respective clients, possessing the authority to manage data and approve data deletions. Each client must have at least one but no more than two active Data Stewards. Access to client pages on REITSuite will be restricted until an active Data Steward is assigned.
                            </p>
                            <h4 id="overview" style={{ paddingBottom: '3px' }}> Deletion Timelines</h4>

                            <p>Data deletion timelines depend on the type of engagement:</p>
                            <ul>
                                <li style={{ marginBottom: '0px' }}>For Non-Audit ARQC Engagements, files older than {dataPurgeConfigurationParameter_Regular_Value}  years are marked for deletion.</li>
                                <li style={{ marginBottom: '0px' }}>For Audit ARQC Only Engagements, files older than {dataPurgeConfigurationParameter_ARQC_Value}  years are marked for deletion.</li>
                            </ul>
                            <p>These deletion timelines also apply to Bulk Report Packages. Deleting marked data occurs every year on the first Friday of December.
                            </p>

                            <h4 id="overview" style={{ paddingBottom: '3px' }}> Bulk Report Packages</h4>
                            <p>
                                Bulk Report Packages are collections of multiple reports grouped together for easier handling. According to our retention policy, any Bulk Report Package holding data or report periods that meet the criteria for deletion will be erased during the yearly deletion process. The deletion process applies to the package, including all associated files.
                            </p>

                            <h4 id="overview" style={{ paddingBottom: '3px' }}> Email Notifications and Approvals</h4>

                            <p>We manage pending data deletions by sending frequent email notifications to Data Stewards:</p>
                            <ul>
                                <li style={{ marginBottom: '0px' }}>Initial requests for approvals are dispatched 3 months, 2 months, 1 month, and 2 weeks before the deletion date, with daily email notifications being sent in the last week leading up to the deletion date.</li>
                                <li style={{ marginBottom: '0px' }}>Reminder emails are sent 4 weeks and 2 weeks before the actual deletion date, once the deletion has been approved.</li>
                            </ul>

                            <h4 id="overview" style={{ paddingBottom: '3px' }}> Data Export</h4>
                            <p>
                                For record-keeping and data management purposes, we provided an &quot;Export Data Deletion Summary&quot; function. This allows to download an exact record of the data due for deletion.
                            </p>
                            <p>
                                Our policy restricts creating report periods beyond one full calendar year prior to the current year. For example, in the year 2023, the system allows the creation of report periods dating back to only Q1 2022 at the most.
                            </p><p>
                                We hope this summary provides a comprehensive view of our Data Retention Policy. For more detailed information or any queries, contact your System Administrator.
                            </p>
                            <p>
                                Thank you for helping us maintain safe, responsible data management within REITSuite.
                            </p>
                        </div>
                    }               
                </div>
            </div>
        </div>

    );
}


export default DataRetentionManagementPolicy;



