import PropTypes from "prop-types";
import React from "react";
import { reduxForm } from "redux-form";
import { MotifFormField, MotifCheckbox, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton } from "@ey-xd/motif-react";

const UpcomingDataDeletionNoticeModal = ({
    showUpcomingDataDeletionNoticeMsg,
    toggleUpcomingDataDeletionNoticeMsg,
    upcomingDataDeletionckChecked,
    handleUpcomingDataDeletionNotice,
    handleUserPreferenceSelectedForDataDeletionNotice,
    upcomingDataDeletionNoticeData
}) => {

    const scheduledDataPurgeDate = upcomingDataDeletionNoticeData.scheduledDataPurgeDate 
        ? (new Date(upcomingDataDeletionNoticeData.scheduledDataPurgeDate))
            .toLocaleString('en-US', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }) 
        : '';
    return (
        <form>
            <MotifModal size="xl" className="data-purge-notification-modal" variant="info" show={showUpcomingDataDeletionNoticeMsg} onClose={toggleUpcomingDataDeletionNoticeMsg} data-testid="UpcomingDataDeletionNotice">
                <MotifModalHeader closeModalButton={<span></span>}>
                    REITSuite Data Management Alert
                </MotifModalHeader>
                <MotifModalBody>
                    Alert: Kindly note that REITSuite is scheduled to carry out a data deletion exercise on {scheduledDataPurgeDate}. Please be aware that
                    <ul>
                        <li><span>For Audit ARQC Only Engagements, any files Q4 {upcomingDataDeletionNoticeData.auditClientDataPurgeYear} and older will be deleted.</span></li>
                        <li><span>For Non-Audit ARQC Engagements, any files Q4 {upcomingDataDeletionNoticeData.nonAuditClientDataPurgeYear} and older will be deleted.</span></li>
                    </ul>
                    If you are a Data Steward and have yet to approve the deletion, please do so as soon as possible. All other users, we remind
                    you to reach out to respective Data Steward promptly for the necessary approvals.
                    <br></br>
                    <MotifFormField>
                        <MotifCheckbox
                            type="checkbox"
                            data-testid="chkbxUpcomingDataDeletionAck"
                            name="upcomingDataDeletionAck"
                            id="upcomingDataDeletionAck"
                            checked={upcomingDataDeletionckChecked}
                            onChange={handleUserPreferenceSelectedForDataDeletionNotice}
                            className="mt-3"
                        >
                            <MotifLabel>I understand and do not show this message again</MotifLabel>
                        </MotifCheckbox>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="primary" className="right" type="button" data-testid="Confirm" 
                        onClick={() => handleUpcomingDataDeletionNotice()}>
                        Confirm
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </form >
    );
};

UpcomingDataDeletionNoticeModal.propTypes = {
    showUpcomingDataDeletionNoticeMsg: PropTypes.bool,
    upcomingDataDeletionckChecked: PropTypes.bool,
    toggleUpcomingDataDeletionNoticeMsg: PropTypes.func,
    handleUpcomingDataDeletionNotice: PropTypes.func,
    upcomingDataDeletionNoticeData: PropTypes.object
};

export default reduxForm({ form: "upcomingDataDeletionNotice", enableReinitialize: true })(
    UpcomingDataDeletionNoticeModal
);
